import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Function to set viewport height variable
const setVh = () => {
  // Calculate viewport height and set CSS variable
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );
};

// Run on load
setVh();

// Add event listeners for resize and orientation change
window.addEventListener("resize", () => {
  setTimeout(setVh, 100); // Delay for Safari
});
window.addEventListener("orientationchange", setVh);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
