import React, { useEffect, useRef, useState } from "react";
import Team from "./Team.tsx";
import Contact from "./Contact.tsx";
// import HamburgerMenu from "../components/HamburgerMenu.tsx";
import ContentSection from "../components/ContentSection.tsx";
import Bio from "../assets/Bio.png";
import Problem from "../assets/Problem.png";
import Solution from "../assets/Solution.png";
import TargetAudience from "../assets/TargetAudience.png";
import Competition from "../assets/Competition.png";
import MoneyBusiness from "../assets/MoneyBusiness.png";
import Scrollbar from "../components/Scrollbar.tsx";

const ForInvestors = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const sections = [
    ".team",
    ".bio",
    ".problem",
    ".solution",
    ".audience",
    ".advantages",
    ".revenue",
    ".investment",
  ];

  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = container.current;

    const handleScroll = () => {
      if (element) {
        const scrollPosition = element.scrollTop;
        const scrollHeight = element.scrollHeight - element.clientHeight;
        const scrollPercent = (scrollPosition / scrollHeight) * 100;
        setCurrentSection(scrollPercent);
      }
    };

    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollPos = (7 / sections.length) * currentSection;

  return (
    <div id="pages-container" ref={container}>
      <Scrollbar scrollPos={scrollPos} />
      <div className="team" id="team">
        <Team />
      </div>
      <ContentSection
        id="bio"
        header="Igar “The Biological Experiment” Dyachenko"
        description={[
          "A dyslexic 2x Karate World Champion, Igar’s journey from Ukraine to the U.S. drives his mission to help neurodivergent children thrive.",
          "As a wellness expert, he’s developed MindScriber to unlock the full potential of learners worldwide.",
        ]}
        image={Bio}
      />
      <ContentSection
        id="problem"
        header="Memory Limitations and Learning Challenges"
        description={[
          "10% of the global population, or 700M learners, are neurodivergent. MindScriber unlocks their potential by addressing their unique learning needs.",
          "Both neurodivergent and neurotypical learners face burnout, frustration, and poor retention as outdated systems fail to support their needs.",
        ]}
        image={Problem}
      />
      <ContentSection
        id="solution"
        header="Absorb Knowledge While You Sleep – Listening App"
        description={[
          "MindScriber scales seamlessly, powered by text-to-speech and FDA-approved neurotech, absorbing information during sleep and tailoring content to cognitive rhythms for enhanced retention.",
          "Whether with or without wearables, MindScriber adjusts lessons to optimize your learning journey.",
        ]}
        image={Solution}
      />
      <ContentSection
        id="audience"
        header="Who Benefits? Broad Access to Learning"
        description={[
          "MindScriber serves 700M neurodivergent learners and millions more, offering personalized tools and strategic partnerships with schools, ensuring broad access to cognitive optimization. ",
          "From students to busy professionals, it helps users of all backgrounds improve their skills and learning efficiency.",
        ]}
        image={TargetAudience}
      />
      <ContentSection
        id="advantages"
        header="Unique Advantages: Why MindScriber Stands Out"
        description={[
          "MindScriber stands apart with patented technologies and real-time health monitoring, offering a holistic approach that outpaces competitors like Speechify by focusing on retention and neurohealth.",
          "While competitors focus on limited features, MindScriber integrates sleep-based learning, positioning us to dominate this growing market with scalable, multi-platform technology.",
        ]}
        image={Competition}
      />
      <ContentSection
        id="revenue"
        header="A Billion-Dollar Opportunity"
        description={[
          "With the e-learning market set to reach $370B by 2026 and a $6.5B TAM in neurodivergent and cognitive health, MindScriber is ready to lead with patented, scalable technology, capturing market share through targeted marketing, partnerships, and an aggressive go-to-market approach.",
        ]}
        image={MoneyBusiness}
      />
      <div className="investment" id="investment">
        <Contact />
      </div>
    </div>
  );
};

export default ForInvestors;
