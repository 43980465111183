import React from "react";
import styled from "styled-components";

const Scrollbar = ({ scrollPos }) => {
  return (
    <>
      <ProgressBarBackground />
      <ProgressIndicator scrollPos={scrollPos} />
    </>
  );
};

export default Scrollbar;

const ProgressIndicator = styled.div<{ scrollPos: number }>`
  position: fixed;
  right: 0;
  top: ${({ scrollPos }) => `${scrollPos}dvh`};
  width: 1.5%;
  border-radius: 50px;
  height: 12%;
  background-color: #4caf50;
  z-index: 999;
  //   transition: top 0.5s ease-out;
`;

const ProgressBarBackground = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 1.5%;
  height: 100dvh;
  background-color: #d3f1e2;
  z-index: 998;
`;
