import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { teamMembersData } from "../assets/teamData.ts";

const Contact: React.FC = () => {
  const [imageSize, setImageSize] = useState(246);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageSize(120);
      } else {
        setImageSize(246);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ContactContainer>
      <ContactMainTitle>Investment Requirements</ContactMainTitle>
      <ContactDescription>
        Join the next <ContactHighlight>billion-dollar</ContactHighlight>{" "}
        cognitive learning app. We’re raising $500K to fuel our market entry,
        targeting a $6.5B addressable market with patented tech that
        revolutionizes learning for neurodivergent users.
      </ContactDescription>

      <ContactWrapper>
        <ContactPerson>
          <ContactImage size={imageSize}>
            <img
              src={Object.values(teamMembersData)[0].image}
              alt={Object.values(teamMembersData)[0].name}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </ContactImage>
          <ContactContent>
            <h3>{Object.values(teamMembersData)[0].name}</h3>
            <p>
              {Object.values(teamMembersData)[0].role}
              <br /> at MindScriber Inc
              <br />
              (New York, New York, USA)
            </p>
          </ContactContent>
        </ContactPerson>

        <ContactInfo>
          <h3>Contact:</h3>

          <ContactItem onClick={() => window.open(`tel:+12125376441`)}>
            <ContactLogo src={require("../assets/Whatsapp.png")} alt="Phone" />
            <p>+1-212-537-6441</p>
          </ContactItem>

          <ContactItem
            onClick={() => window.open(`mailto:Igar@MindScriber.com`)}
          >
            <ContactLogo src={require("../assets/Email.png")} alt="Email" />
            <p>Igar@MindScriber.com</p>
          </ContactItem>

          <ContactItem
            onClick={() => window.open(`https://t.me/IgarDyachenko`)}
          >
            <ContactLogo
              src={require("../assets/Telegram.png")}
              alt="Telegram"
            />
            <p>IgarDyachenko</p>
          </ContactItem>
        </ContactInfo>
      </ContactWrapper>
    </ContactContainer>
  );
};

export default Contact;

// Styled-components
const ContactContainer = styled.div`
  background-color: #f4fdf9;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: -webkit-fill-available;
  min-height: 100dvh;
  height: auto;
`;

const ContactMainTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const ContactDescription = styled.p`
  line-height: 1.5;
  font-size: 2rem;
  width: 70%;
  p {
    letter-spacing: 0.02rem;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    width: 75%;
    margin: 0% 0%;
  }
`;

const ContactHighlight = styled.span`
  background-color: #b6dfc880;
  border-radius: 50px;
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactPerson = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContactImage = styled.div<{ size: number }>`
  background: #dcf2e7;
  border: 1px solid #28793e;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Image size adjustments */
  width: 180px;
  height: 180px;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (max-height: 600px) {
    width: 80px;
    height: 80px;
  }
`;

const ContactContent = styled.div`
  margin-top: 7%;

  h3 {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    margin: 0 auto;
    margin-top: 2%;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 1rem;
    }
  }

  @media (max-height: 600px) {
    h3 {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  h3 {
    margin: 0;
    margin-bottom: 8%;
  }
  @media (max-width: 768px) {
    margin-top: 3%;
  }
  @media (max-height: 600px) {
    h3 {
      font-size: 1rem;
    }
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 5%;

  &:hover {
    background-color: #1e5a3021;
    transform: scale(1.05);
    border: 1px solid #28793e;
  }

  p {
    margin: 0;
  }
`;

const ContactLogo = styled.img`
  width: 35px;
  height: auto;
  margin-right: 3%;
  border-radius: 50px;

  transition: transform 0.3s ease, background-color 0.3s ease, border 0.3s ease;
  box-sizing: border-box; /* Prevents layout shifts when border is added */

  @media (max-width: 768px) {
    width: 30px;
  }

  &:hover {
    cursor: grab;
    background-color: #1e5a3021;
    transform: scale(1.05); /* Scales the icon */
    border: 1px solid #28793e; /* Optional border for hover effect */
  }
`;
