import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/notFound.css";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const [logoSize] = useState(200);

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="not-found-container">
      <img
        src="/logo500.png"
        alt="MindScriber Logo"
        className="animated-logo"
        style={{
          width: `${logoSize}px`,
          height: `${logoSize}px`,
          marginBottom: "20px",
        }}
      />
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">Page cannot be found</p>
      <button onClick={handleGoHome} className="not-found-button">
        Go to Home Page
      </button>
    </div>
  );
};

export default NotFound;
