import React from "react";
import styled, { keyframes } from "styled-components";

interface ContentSectionProps {
  id: string;
  header: string;
  description: string[];
  bullets?: string[];
  image: string;
}

const ContentSection: React.FC<ContentSectionProps> = ({
  id,
  header,
  description,
  bullets,
  image,
}) => {
  return (
    <Container id={id} className={id}>
      <LeftSection>
        <Header>{header}</Header>
        {description.map((desc, index) => (
          <Description key={index}>{desc}</Description>
        ))}
        {bullets && (
          <BulletContainer>
            {bullets.map((bullet, index) => (
              <Bullet key={index}>
                <BulletSymbol>•</BulletSymbol> {bullet}
              </Bullet>
            ))}
          </BulletContainer>
        )}
      </LeftSection>
      <RightSection>
        <Image src={image} alt="content image" />
      </RightSection>
      <ContactInfo>
        <ContactLogo
          src={require("../assets/Whatsapp.png")}
          alt="Phone"
          onClick={() => window.open(`tel:+12125376441`)}
        />
        <ContactLogo
          src={require("../assets/Telegram.png")}
          alt="Telegram"
          onClick={() => window.open(`https://t.me/IgarDyachenko`)}
        />
        <ContactLogo
          src={require("../assets/Email.png")}
          alt="Email"
          onClick={() => window.open(`mailto:Igar@MindScriber.com`)}
        />
      </ContactInfo>
    </Container>
  );
};

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components
const Container = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 20px;
  min-height: -webkit-fill-available;
  min-height: 100dvh;
  height: auto;
  transition: all 0.5s ease-in-out;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  animation: ${fadeIn} 0.8s ease-out;
  @media (max-width: 768px) {
  }
`;

const Header = styled.h1`
  font-size: 4rem;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media (max-height: 600px) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1.7rem;
  margin-bottom: 5%;
  line-height: 1.5;
  letter-spacing: 0.02rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-height: 600px) {
    font-size: 0.9rem;
  }
`;

const BulletContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 10px;
`;

const Bullet = styled.h5`
  font-size: 1.7rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 2%;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const BulletSymbol = styled.span`
  color: #61a781;
  margin-right: 5px;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  @media (max-width: 768px) {
  }
`;

const Image = styled.img`
  width: 90%;
  object-fit: cover;
  margin-bottom: 10%;

  @media (max-width: 768px) {
    width: 75%;
  }
  @media (max-height: 600px) {
    width: 60%;
  }
`;

const ContactInfo = styled.div`
  position: absolute;
  bottom: 60px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContactLogo = styled.img`
  width: 50px;
  height: auto;
  border-radius: 50px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-sizing: border-box;
  &:hover {
    cursor: grab;
    background-color: #1e5a3021;
    transform: scale(1.1);
    border: 1px solid #28793e;
  }
  @media (max-width: 768px) {
    width: 35px;
  }
`;

export default ContentSection;
