import React from "react";
import styled from "styled-components";
import { teamMembersData } from "../assets/teamData.ts";

const Team: React.FC = () => {
  return (
    <TeamContainer>
      {/* <Header>
        <h5>
          <Highlight>INSPIRING</Highlight> LEARNING APPETITE
        </h5>
      </Header> */}
      <Header>
        <h1>4x Faster Learning with MindScriber – The #1 Night Learning App</h1>
      </Header>
      <Description>
        <p>
          Targeting a $370B global e-learning market and a $6.5B neurodivergent
          learning market by revolutionizing education with FDA-approved
          technology, led by experts in AI, medicine, and education.
        </p>
      </Description>
      <Cards>
        {Object.values(teamMembersData).map((member, index) => (
          <Card key={index}>
            <CardImage>
              <img src={member.image} alt={member.name} />
            </CardImage>
            <CardContent>
              <h3>{member.name}</h3>
              <h3>{member.role}</h3>
              <p>{member.description}</p>
            </CardContent>
          </Card>
        ))}
      </Cards>
    </TeamContainer>
  );
};

const TeamContainer = styled.div`
  padding: 0 2.5%;
  background-color: #f4fdf9;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  min-height: -webkit-fill-available;
  min-height: 100dvh;
  height: auto;
`;

// const Header = styled.div`
//   font-size: 0.5rem;
//   margin: 0;
// `;

// const Highlight = styled.span`
//   background-color: #b6dfc8;
//   border-radius: 50px;
//   padding: 0 8px;
// `;

const Header = styled.div`
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
  @media (max-height: 600px) {
    font-size: 0.5rem;
  }
`;

const Description = styled.div`
  line-height: 1.5;
  font-size: 2rem;
  width: 75%;
  p {
    letter-spacing: 0.02rem;
    margin: 0;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    width: 90%;
  }
  @media (max-height: 600px) {
    font-size: 0.9rem;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-around;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 25%;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 50%;
  }
`;

const CardImage = styled.div`
  background: #dcf2e7;
  border: 1px solid #28793e;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Image size adjustments */
  width: 180px;
  height: 180px;

  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
  }

    @media (max-height: 600px) {
        width: 60px;
    height: 60px;
  }
  }
`;

const CardContent = styled.div`
  margin-top: 10px;

  h3 {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    margin: 0 auto;
    margin-top: 3%;
    width: 50%;
    text-align: center;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 0.8rem;
    }
    p {
      font-size: 0.6rem;
      width: 90%;
    }
  }
`;

export default Team;
