import { TeamMember } from "../interfaces/teamMember";
import Igar from "./Igar.png";
import Mark from "./Mark.png";
import Alexander from "./Alexander.png";
import Omer from "./Omer.png";

export const teamMembersData: Record<string, TeamMember> = {
  Igar: {
    image: Igar,
    name: "Igar Dyachenko",
    role: "Co-Founder, CEO",
    description:
      "A serial entrepreneur and two-time world karate champion, he embodies the vision and strategic growth of MindScriber.",
  },
  Mark: {
    image: Mark,
    name: "Dr. Mark Litvak",
    role: "Co-Founder, COO",
    description:
      "Integrates traditional and alternative medicine, ensuring MindScriber enhances both cognitive health and holistic well-being.",
  },
  Alexander: {
    image: Alexander,
    name: "Dr. Alexander Riftine",
    role: "Chief Algorithm Officer",
    description:
      "A pioneer in modern machine learning with over three decades of experience researching heart rate variability and physiology.",
  },
  Omer: {
    image: Omer,
    name: "Omer Cohen",
    role: "Chief Technology Officer",
    description:
      "Full Stack developer with extensive game development experience, driving MindScriber's seamless, innovative learning tools.",
  },
};
