import React, { useState, useEffect } from "react";
import { signInAnonymously } from "firebase/auth";
import { auth, db } from "../config/firebase.ts";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "../styles/login.css";

const Login: React.FC = () => {
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Sign in anonymously when the component mounts
  useEffect(() => {
    const authenticateAnonymously = async () => {
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error("Error signing in anonymously:", error);
      }
    };

    authenticateAnonymously();
  }, []);

  // Automatically attempt login when `code` changes
  useEffect(() => {
    const loginWithCode = async () => {
      if (code.length === 13) {
        try {
          // Query Firestore to find the user with the entered code
          const usersRef = collection(db, "users");
          const q = query(usersRef, where("code", "==", code));
          const querySnapshot = await getDocs(q);

          if (querySnapshot.empty) {
            setError("Invalid code, please try again.");
            return;
          }

          const userDoc = querySnapshot.docs[0];
          const userData = userDoc.data();

          // Update loginCount
          const loginCountRef = doc(db, "users", userDoc.id);
          await updateDoc(loginCountRef, {
            loginCount: (userData.loginCount || 0) + 1,
          });

          navigate("/for-investors");
        } catch (err) {
          setError("Login failed. Please check your code and try again.");
          console.error("Error details:", err);
        }
      }
    };

    loginWithCode();
  }, [code, navigate]);

  return (
    <div className="login-container">
      <div>
        <img
          src="/logo500.png"
          alt="MindScriber Logo"
          style={{ width: "150px", height: "150px", marginBottom: "20px" }}
        />
      </div>
      <div style={{ width: "75%" }}>
        <h1 style={{ fontSize: "1.6rem" }}>
          Investors, Welcome to MindScriber - The #1 Night Learning App
        </h1>
      </div>
      <div style={{ paddingTop: 100 }}>
        <input
          type="password"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter Code"
          className="input"
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
};

export default Login;
